<script lang="ts" setup>
const props = defineProps({
    src: {
        type: String,
        default: null
    },
    format: {
        type: String,
        default: 'webp'
    },
    alt: {
        type: String,
        default: 'image'
    }
})

// 假設所有 /storage 開頭的路徑都是Api的圖片
const isInternal = props.src?.startsWith('/storage')
const isExternal = props.src?.startsWith('http://') || props.src?.startsWith('https://')

// 依圖片來源型別建構URL
const constructUrl = () => {
    if (isExternal) {
        // 外部URL不變
        return props.src
    } else if (isInternal) {
        // 內部URL，如果需要，這裡可以加入一些邏輯來修改或加上前綴
        // 例如，如果你的靜態資源伺服器位址不同於應用程式的主域名
        return `${import.meta.env.VITE_DOMAIN}${props.src}`
    } else if (props.src) {
        // 專案內圖片
        return props.src
    }
    // 預設圖片路徑
    return '/images/default.jpg';
}

const imageSrc = ref(constructUrl())
</script>

<template>
    <nuxt-img :src="imageSrc" :format="format" :alt="alt" :title="null" />
</template>
